
.abouth4{
    text-align: left;
}

.text-box p {
text-align: justify;
font-family: 'Poppins', sans-serif;
margin-bottom: 30px;
    font-size: 20px;
    line-height: 37px;
}


