.preloaderbg{

    background-color: #000000;
    height: 100vh;
    width: 100%;
   
   
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
   
  
     position: fixed; 
    left: 0;
    bottom: 0;
    z-index: 11;
    overflow: hidden;
    transition: all 0.5s ease;
    transition-duration: 100ms;
    -webkit-transition-duration: 1100ms;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);

}
/* .page-loaded .preloaderbg {
  bottom: -100%;
  transition-delay: 3s;
} */
.preloaderbg  .midinner {
  display: block;
  margin: 0;
}
.preloaderbg  .midinner {
  margin: 0;
    position: relative;
    z-index: 3;
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    transition: all 2s ease;
    transition-duration: 3337000ms;
    -webkit-transition-duration: 3337000ms;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 100);
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1000);
}

   .preloaderbg .midinner{
  transform: translateY(30px);
  opacity: 1;
}  
  .preloaderbg .layer:nth-child(1) {
  left: 0;
  transition-delay: 0.15s;
}
.preloaderbg .layer:nth-child(2) {
  left: 25%;
  transition-delay: 0.20s;
}
.preloaderbg .layer:nth-child(3) {
  left: 50%;
  transition-delay: 0.25s;
}
.preloaderbg .layer:nth-child(4) {
  left: 75%;
  transition-delay: 0.30s;
}   
.page-loaded .preloader .layer {
  bottom: -100%;
}


  .page-loaded.preloaderbg {
  bottom: -100%;
  transition-delay: 3s;
}   

.preloaderbg .layer {
  width: 25%;
  height: 100%;
  position: absolute;
  bottom: 0;
  background: #000;
  z-index: 2;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  transition-duration: 100ms;
  -webkit-transition-duration: 100ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.Avignaimg{
    width: 415px;
    height: 415px;
}
.typewriting{
  color: #fff;
  
}
.Typewriter__cursor{
  color:#FD5B68 !important;
}
.Typewriter__wrapper{
  font-size: 13px;
  font-family:fjalla  ;
}


