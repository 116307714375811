.ourdes{
    text-align: left;
  
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 37px;
    color:#fff;
}

.ourvalueshead{
    width: 100%;

    line-height: 1;
    margin-bottom: 20px;
 
    opacity: 0.5;
    font-size: 14px;
    color: #fff;
}
.valtitle{
    margin-bottom: 50px;
   
}
.valtitle h2{ 
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    position: relative;
    color: #fff;
}

.valtitle h2::before{ 
    content: attr(data-text);
    width: 100%;
    font-size: 20vw;
    opacity: 0.05;
    z-index: 0;
    position: absolute;
    left: 0;
    top: -20%;
}

   
   

.light{
    color: #fff;
}



.ourvaluescon{
    background-color: #0a0a0a;
    padding: 150px 0px !important;
    text-align: left;


}
.noise{
    position: relative;
}
 .noise::before{
   
    content: "";
    width: 100%;
    height: 100%;
    background: url(../../Assets/videos/bg-noise.gif);
    opacity: 0.04;
    position: absolute;
    left: 0;
    top: 0;
}


.servicesbox{
    color: #fff;
} 
.servicesbox span{
    color: #fff;
    width: 50px;
    height: 1px;
    background: #FD5B68;
    display: inline-block;
    margin: 5px 15px;
}
.servicesbox h4{
  
    font-size: 17px;
    margin-bottom: 30px;
}

.leftdiv{
    margin-top: -2px;
    text-align: left;
}
.servicesbox small{
    color: #FD5B68;
}
.rightdiv  h4 b{
    font-family: fjalla;
}

@media (max-width: 768px) {
    .valtitle{
        margin-bottom: 30px;

    }
    .valtitle h2{
        font-size: 32px;
    }
    .servicesbox{
        margin-top: 30px;
    }
    .ourvaluescon{
        padding:80px 0px!important;
    }
   
}