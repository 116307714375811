.ourportfoliobg{
    position: relative;
    background-image: url(../../Assets/images/ourportfoliobg-img.svg);
    padding: 150px 0px !important;
}
.ourportfoliotitle{
    text-align: left;
    margin-bottom: 50px;
}
.ourportfoliotitle h6 {
    width: 100%;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
    font-family: 'Fjalla One', sans-serif;
    opacity: 0.5;
    font-size: 14px;
}
.ourportfoliotitle h2 {
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    position: relative;
    color: #0e0e0e;
}
.portfolioimges{
    width: 100%;
    height: auto;
}
.client-box{
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 30px;
    border: 1px solid #eee;
    background: #fff;
    padding: 15px;
}
.client-box img{
    opacity: 1;
    display: block;
}

.client-box :hover{
    opacity: 0.2;

  
}





.client-box small:hover{
    opacity: 1;
   
    
        width: 100%;
        height: 100%;
        line-height: 50px;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        font-size: 25px;
        display: grid;
        background-color:#ffffffe3;
        
  
        font-family:  fjalla;
    font-weight: 400;
    
        place-content: center;
        font-size: 22px;
        transition: .25s ease-in-out;
    }
    


.client-box small{
width: 100%;
height: 50px;
line-height: 50px;
position: absolute;
left: 0;
font-size: 25px;
top: 50%;
text-align: center;
font-size: 600;
opacity: 0;
font-family:  fjalla;
font-size: 22px;
font-weight: 400;

}
.emptydiv{
    height: 25px;

}
.text-box .custom-link {
    margin-top: 30px;
}
@media (max-width: 768px) {
    .ourinvesttittal{
    margin-bottom: 30px;}
    .ourinvesttittal h2 {
        font-size: 32px;
       
      }
      .ourinvestmentcon{
        padding: 80px 0px;
      }
      .ALLROW{
        padding: 35px 0;
      }
      .ourportfoliobg{
        padding:80px 0px !important ;
      }
      .ourportfoliotitle h2{
        font-size: 32px;
      }
   
  }
