.investmentcon{
    padding: 150px 0px !important;
} 
.investheading{
    width: 100%;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
  font-family: fjalla;

    opacity: 0.5;
    font-size: 14px;
 
}
.investappsubheading{
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    /* word-spacing:-12px; */
    position: relative;
    color: #0e0e0e;
}
.investappsubheading::before{
    content: attr(data-text);
    width: 100%;
    font-size: 18vw;
    opacity: 0.05;
    z-index: 0;
    position: absolute;
    
    left: 0;
    top: -20%;
}

.invlesappttittal{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    text-align: left;
}
.investappimg{
    width: 100%;
    height: auto;
}
.investdesh4{
    width: 100%;
    display: block;
   text-align: left;
    
    font-size: 25px;
    line-height: 40px;
    text-transform: uppercase;
}
.imgbox1{
    margin-bottom: 30px;
    padding: 0px;
}
.text-box{
    width: 100%;

}



.inevestappdes{
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 37px;
    text-align: justify;
}

@media (max-width: 768px){
    
    .invlesappttittal{
       margin-bottom: 30px; 
    }
    .investappsubheading{
        font-size: 32px;
        /* word-spacing: -8px; */
    }
    .investmentcon{
        padding: 80px 0px !important;
    }
    }



