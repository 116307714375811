
.pageheader {
    height: 100vh;
    min-height: 660px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    
    width: 100%;
    background: rgb(14, 14, 14);
    opacity: 1;
    transition: all 0.05s ease-in-out 0s;
}
  
  /* .pageheader{
    width: 100vw;
  }
   */
  .leftsidetag ul {
    margin: 0 auto;
    padding: 0;
  }
  .leftsidetag ul a {
    text-decoration: none;
    /* font-weight: 700; */
    font-size: 80%;
  }
  
  .leftsidetag {
    width: 50%;
  
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  
    z-index: 3;
    height: 25px;
    line-height: 25px;
    margin-left: 50px;
  }
  
  .liststylebg {
    list-style: none;
  }
  .scrolldwn1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
  }
  .scrolldwn1 small {
    width: 98px;
    display: inline-block;
    transform: rotate(-90deg);
    margin-bottom: 50px;
    color: #fff;
    font-family:fjalla;
    font-size: 80%;
 font-weight: 400;
  }
  .video-bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 0;
  }
  .pageheader .video-bg:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url(../../Assets/images/bg-lines.png) center;
    z-index: 2;
  }
  .video-bg .videobgname {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    opacity: 0.8;
  }
  
  .leftsidetag ul li {
    display: inline-block;
    margin: 0 10px;
    padding: 0;
    list-style: none;
  }
  /* .scrolldwn1 span {
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -0.5px;
    width: 1px;
    height: 70px;
    background: #fff;
    content: "";
  } */
  .leftsidetag ul li a {
    font-size: 14px;
    color: #fff;
     font-family:fjalla; 
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -ms-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    padding-bottom: 6px;
    border-bottom: 2px solid transparent;
  }
  .pageheader .midpart {
    position: relative;
    z-index: 2;
  }
  
  .scrolldwn1 span {
    display: block;
    height: 50px;
    position: relative;
    transform: translateX(-1px);
  }
  
  .pageheader .container {
    position: relative;
    z-index: 3;
    padding: 0 10% !important;
}
  
  .tagline .headerline1::after {
    content: "";
    width: 22px;
    height: 1px;
    float: right;
    margin: 10px;
    
    background:#e8293b ;
  }
  
  /* @media (min-width: 1170px){
      .container {
      max-width: 1100px;
  }} */
  
  
  
  
 
   .pageheader .headingline2 {
    font-weight: 800;
     color: #fff;
    font-size: 10vw;
    line-height: 1;
    text-align: left;
  }

  .pageheader .container p {
    margin-bottom: 0;
    font-family:fjalla;
    color:#FD5B68;
    text-align: left;
    font-size: 14px;
}
  