.content-section{
    
        width: 100%;
        display: block;
        padding: 150px 0 !important;
    
}
.contactrow{
    padding-left: 20px;
    padding-right:20px ;
    
    display: flex;
    justify-content: center;
  
}
.formcon{
    width: 50%!important;
}

.contact-title{
    
        margin-bottom: 50px;
    
    
}
.contact-title h2{
    
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    position: relative;
    color: #0e0e0e;
    text-align: center;


}
.innercol input{
    width: 100%;
    height: auto;
    font-size: 14px;
    outline: 0;
    box-shadow: none;
    margin-bottom: 10px;
    padding: 6px 12px;
    color: #494d50;
     border: 1px solid #8e98a2; 
    margin-left: 0;
    margin-right: 0;
}
.innercol input:focus{
    outline: 0;
     border: 1px solid #0095ff;} 


.astrick{
    color: #fa5252;
    font-weight: 700;
    border: 0!important;
    text-decoration: none;
    font-family:  'Poppins', sans-serif;
    font-size: 14px;
}

.innercol textarea{
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 120px;

  
       
        font-size: 14px;
        outline: 0;
        box-shadow: none;
        margin-bottom: 10px;
        padding: 6px 12px;
        color: #494d50;
        border: 1px solid #8e98a2;
        margin-left: 0;
        margin-right: 0;
    
}
.errorp{
    font-weight: 400;
    font-size: 14px!important;
    color: #fa5252;
    margin: 0px!important;
}
/* .sucess{
    background-color: #4bce61;
    padding: 13px 3em;
    margin: 0 0 2em;
    position: relative;

    color: #fff;
    border-radius: 5px;
    font-family:  'Poppins', sans-serif;

} */
/* .sucess::before{
    font-family: dashicons;
    content: '\2713';
    position: absolute;
    top: 1em;
    left: 1.5em;
    color: inherit;
} */



.dropdown1{
    width: 100% ;
    height: auto;
    font-size: 14px;
    outline: 0;
    box-shadow: none;
    margin-bottom: 10px;
    padding: 6px 12px;
    color: #494d50;
    border: 1px solid #8e98a2;
    margin-left: 0;
    margin-right: 0;
}
.dropdown2:disabled{
    color: rgb(170, 170, 170)!important; 


}
.contactlabel{
    font-weight: 600;
    line-height: 1.7;
    margin: 0 0 10px 0;
   /* color:  #494d50; */
    
    font-size: 14px;
}
.form-row{
    margin-bottom: 10px;
}
.dropdown2{
    height: 180px;
}
.textarea{
    width: 100%;
}
.contactbutton{
    color: #555;
    height: auto;
    cursor: pointer;
    background: 0 0;
    box-shadow: none;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.5;
    padding: 10px 15px;
    border-radius: 3px;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
}


@media (max-width: 768px) {
    .contact-title h2{
        font-size: 32px;
    }
    .formcon {
        width: 100% !important;


    }
    .content-section{
    
        width: 100%;
        display: block;
        padding: 80px 0 !important;
    
}
    /* .sucess::before {
        font-family: dashicons;
        content: '\2713';
        position: absolute;
        top: 2em;
        left: 1.5em;
        color: inherit;
    } */
    /* .sucess {
        background-color: #4bce61;
        padding: 13px 3em;
        margin: 0 0 2em;
        position: relative;
        color: #fff;
        border-radius: 5px;
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
    } */
           
        }

