.formcon2{
    width: 50% !important;
    text-align: center;
    padding: 80px !important ;
    background-color: #fff;
    box-shadow: 29px 29px 60px 0px rgba(0,0,0,0.2);
}
.thnkewpading{
    padding: 10px;
}
.THNKEWNUTTON{

    background-color:#FD5B68 !important;
    color: #fff !important;
    border-radius: 0px !important;
    font-weight: 700px !important;

}
.sucess{
    margin-bottom: 50PX;
    margin-top: 50px;
    font-size: 15px;
color: #000000;
}
@media (max-width: 768px){
    
    .formcon2{
        width: 100%!important;
     
    }
    }
