.headerbanner {
  height: 100vh;
  min-height: 660px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: black;
  width: 100%;
  opacity: 1;
    transition: all 0.05s ease-in-out 0s;
}
/* .headerbanner {
    width: 100vw;
  } */

.leftsidetag ul {
  margin: 0 auto;
  padding: 0;
}
.leftsidetag ul a {
  text-decoration: none;

  font-size: 80%;
}
.btnimg {
  visibility: hidden;
  margin-left: -39px;
  margin-right: 15px;
visibility: hidden;
  
    transition: transform .3s;
    margin-left: -25px;
    margin-right: 15px;
}

.leftsidetag {
  width: 50%;

  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);

  z-index: 3;
  height: 25px;
  line-height: 25px;
  margin-left: 50px;
}

.liststylebg {
  list-style: none;
}
.scrolldwn1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
}
.scrolldwn1 small {
  width: 98px;
  display: inline-block;
  transform: rotate(-90deg);
  margin-bottom: 50px;
  color: #fff;
  font-family: fjalla;
  font-size: 80%;
  font-weight: 400;
  /* font-weight: 700; */
}
.video-bg {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
}
.scrollbox {
  height: 50px;
  position: relative;
  transform: translateX(-1px);
}
.scrollbox::before {
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -0.5px;
  width: 1px;
  height: 70px;
  background: #fff;
  content: "";
}
.headerbanner .video-bg:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../../Assets/images/bg-lines.png) center;
  z-index: 2;
}
.headerbanner .video-bg .videobgname {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  opacity: 0.8;
}

.leftsidetag ul li {
  display: inline-block;
  margin: 0 10px;
  padding: 0;
  list-style: none;
}

.leftsidetag ul li a {
  font-size: 14px;
  color: #fff;
  font-family: fjalla;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  padding-bottom: 6px;
  border-bottom: 2px solid transparent;
  font-weight: 400;
}
.headerbanner .midpart {
  position: relative;
  z-index: 2;
}
.tagline {
  display: block;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.scrolldwn1 span {
  display: block;
  height: 50px;
  position: relative;
  transform: translateX(-1px);
}

.headerbanner .container {
  position: relative;
  z-index: 2;
  text-align: left;
}
.tagline .headerline1::after {
  content: "";
  width: 22px;
  height: 1px;
  float: right;
  margin: 10px;

  background: #e8293b;
}

/* @media (min-width: 1170px){
      .container {
      max-width: 1100px;
  }} */

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.headingist {
  opacity: 0.5;
  margin-top: 2px;
  margin-bottom: 0;
  font-weight: 400;
  text-align: left;
}

.tagline span {
  float: left;
  font-weight: 600;
  color: #fff;
  margin-top: -2px;
  margin-bottom: 0px;
}
.headingline2 {
  font-weight: 800;
  color: #fd5d68;
  font-size: 10vw;
  line-height: 1;
  text-align: left;
}
.invest {
  -webkit-text-stroke-width: 1px;
  transform: translateY(-35%);
  margin-left: 4%;
  display: flex;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}



.slide-btn {
  display: block;
  transition: all 0.5s;
  padding-left: 30px;
}
.slide-btn .lines {
  width: 42px;
  height: 1px;
  float: left;
  overflow: hidden;
  -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -ms-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.slide-btn:hover .lines {
  width: 62px;
  margin-left: 20px;

}
.slide-btn:hover .btnimg {
  visibility: visible;
  
}
.slide-btn:hover .lines span:nth-child(1) {
  width: 0;
}

.slide-btn:hover .lines span:nth-child(2) {
  width: 100%;
  transition-delay: 0.20s;
}

.slide-btn a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}
.slide-btn b {
  font-weight: 600;
  margin-left: 20px;
  font-family: poppins;
}
.slide-btn .lines span:nth-child(1) {
  width: 100%;
  height: 1px;
  float: right;
  background: #fff;
}
.slide-btn .lines span:nth-child(2) {
  width: 0;
  height: 1px;
  float: left;
  background: #fff;
}


/* .slide-btn svg {
  width: 50px;
  height: 50px;
  transition: transform .3s;
  margin-left: -25px;
  margin-right: 15px;
}
.slide-btn svg {
  width: 50px;
  height: 50px;
  transition: transform 0.3s;
  margin-left: -25px;
  margin-right: 15px;
} */
.slide-btn .video-play-circle {
  stroke-dasharray: 315;
  stroke-dashoffset: 315;
  transition: stroke-dashoffset 0.5s;
}
@media (min-width: 1170px) {
  .container,
  .container-lg,
  .container-xl {
    max-width: 1100px !important;
  }
}


@media (max-width: 768px) {
  .container {
    padding: 0 30px !important;
  }
  .headingline2 {
   
    font-size: 18vw;
 
}
.investmentcon {
  padding: 80px 0!important;
}
.leftsidetag{
  display: none!important;
}
.slide-btn{
  padding-left:0px;
}
.scrolldwn1{
  left: -10px;
}

}
