.investmanagement{
    width: 100%;
 
    padding: 150px 0 !important;
}
.investtitle {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    text-align: center;
}

.investtitle h6 {
    width: 100%;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
    font-family:fjalla ;
    opacity: 0.5;
    font-size: 14px;
}
.investtitle h2 {
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    position: relative;
    color: #0e0e0e;
}
.investtitle h2:before {
    content: attr(data-text);
    width: 100%;
    font-size: 20vw;
    opacity: 0.05;
    z-index: 0;
    position: absolute;
    left: 0;
    top: -20%;
}
.text-box ul{
    line-height: 2;
}
.text-box ul li{
    font-weight: 400;
    text-align: left;
    font-size: 20px;
  
}
.text-box p{
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 37px;
}
.process-box span {
    width: 80px;
    height: 80px;
    line-height: 62px;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    border-radius: 50%;
    background: #fd5b68;
    position: relative;
    border: 10px solid #fff;
    text-align: center;
}
/* .process-box span:before {
    content: "";
    width: 30vw;
    height: 2px;
    background: #e2fdf1;
    position: absolute;
    left: -77px;
    top: 30px;
    z-index: -1;
} */
.process-box {
    width: 100%;
    display: block;
    position: relative;
    text-align: center;
    
}



.process-box .imgbox {
    width: 100%;
    display: block;
    margin: 20px 0;
    background: #fff;
}
.procesicons{

  height: 90px;  
}
.processboxfig h5 {
    font-weight: 600;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 0;
    color: #0e0e0e;
    font-family: 'Poppins', sans-serif;
}
@media (max-width: 768px){
    .investtitle h2 {
      
        font-size: 32px;
       
    }
    .investmanagement{
        width: 100%;
     
        padding:80px 0px !important;
    }
    }
    
