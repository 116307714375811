.Avtarbox{
    text-align: center;

}
.avatar{
    max-width: 100%; 
    height: 56px;

}
.navbartop {
    width: 100%;
    position: absolute;
   
    top: 0;
    padding: 20px 40px !important;
    color: #fff;
    font-family: 'Fjalla One', sans-serif;
    font-size: 14px;
    z-index: 10;
}
.direction{
    text-decoration: none;
    color: #fff;
    font-family: fjalla;
 
    
    padding-bottom: 6px;
    border-bottom: 2px solid transparent;
}
.direction:hover{
    text-decoration: underline;
    
   
}
  

.crossicon{
width: 21px;
height:21px;
color: #FD5B68;
}
.menu{
    font-weight: 700;
}

.hamburger-menu {
    margin-right: 0;
    margin-left: 60px;
    float: right;
}
.hamburger{
 
    width: 26px;
    height: 18px;
    position: relative;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -ms-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.hamburger-menu .hamburger {
    float: right;
    margin-top: 1px;
}
.hamburger.istline{
    width: 50%;
}
.hamburger span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: #FD5B68;
    z-index: 0;
}
.hamburger span:nth-child(1) {
    top: 0px;
    width: 50%;
}
.hamburger span:before, .navbar .hamburger span:after {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    height: 100%;
    width: 0;
    right: 0;
    left: auto;
}
.hamburger span:before {
    z-index: 1;
    background: #fff;
    transition: all 0.15s linear 0s;
}
.hamburger span:after {
    z-index: 2;
    background:#FD5B68;
    transition: all 0.15s linear 0.3s;
}
.hamburger span:before {
    z-index: 1;
    background: #fff;
    transition: all 0.15s linear 0s;
}
 .hamburger:hover span:after, .hamburger:hover span:before {
    width: 100%;
    left: 0;
    right: auto;
}
 .hamburger:hover span {
    width: 100% !important;
}

.hamburger span:nth-child(2) {
    top: 8px;
}
.hamburger span:nth-child(3) {
    width: 50%;
    top: 16px;
    right: 0;
    left: auto;
}

 .hamburger:hover span:before {
    z-index: 2;
    transition: all 0.15s linear 0.3s;
}
 .hamburger:hover span:after {
    z-index: 1;
    transition: all 0.15s linear 0s;
}
.hamburger:hover span:nth-child(2):before {
    transition-delay: 0.15s;
}
 .hamburger:hover span:nth-child(2):after {
    transition-delay: 0.05s;
}
 .hamburger:hover span:nth-child(3):before {
    transition-delay: 0.20s;
}
.navbar .hamburger:hover span:nth-child(3):after {
    transition-delay: 0.10s;
}
.hamburger:hover span:after {
    z-index: 1;
    transition: all 0.15s linear 0s;
}
.menu{
    display: inline-block;
    font-weight: 400;
    margin-right: 15px;
    font-family: fjalla;
    text-decoration: none;
    color: #fff;
}
@media (max-width: 768px) {

    .navbartop{
        padding: 20px 30px;

    }
    .hamburger-menu{
        margin: auto;
    }
    .navbartop {
        padding: 20px 30px !important;
    
    }
}