.team-memberimg{
    width: 100%;
    display: block;
    margin-bottom: 10px;
    height:auto ;
}
.team-membercol{
    margin:0px auto;
    text-align: left;
}
.team-member h4{
    font-family: 'Fjalla One', sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 0;
}
.team-member small {
    opacity: 0.6;
    font-size: 11px;
    display: block;
    margin-bottom: 5px;
}
