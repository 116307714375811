.bg-layers span{
    transition-delay: 0.3s;

}
.navigationmenu .active .bg-layers span {
  transition-delay: 0s;
}
.menuinside{
    transition-delay: 0.45s;
}
.navigationmenu .bg-layers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    /* background-color: #0e0e0e; */
    /* background-color: #0e0e0e6e; */
    /* background-color: #0e0e0e6e; */
    background-color: #0e0e0ebd;
}
.video-bg .videobgname2{
  
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        /* opacity: 0.2; */
    
}
.navigationmenu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    visibility: hidden;
}
.navigationmenu .innermenu {
    position: relative;
    z-index: 3;
    color: #fff;
    text-align: center;
  
    padding: 96px 0px 0px 0px;
}
.navigationmenu .bg-layers span {
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
     transform: skewX(0); 
    /* background: #0e0e0e; */
}


.navigationmenu .bg-layers span:nth-child(1) {
    left: 0%;
}
.navigationmenu .bg-layers span:nth-child(2) {
    left: 25%;
}
.navigationmenu .bg-layers span:nth-child(3) {
    left: 50%;
}
.navigationmenu .bg-layers span:nth-child(4) {
    left: 75%;
}
.navigationmenu .bg-layers span:nth-child(1):before {
    content: "";
    width: 150%;
    height: 100%;
    /* background: #0e0e0e; */
    position: absolute;
    left: -149%;
    top: 0;
}
.navigationmenu .bg-layers span:nth-child(4):before {
    content: "";
    width: 150%;
    height: 100%;
    /* background:#0e0e0e ; */
    position: absolute;
    right: -149%;
    top: 0;
}

.navigationmenu.active {
    visibility: visible;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
}
.navigationmenu.active .bg-layers span:nth-child(1) {
    width: calc(25% + 1px);
}
.navigationmenu.active .bg-layers span {
    width: calc(25% + 1px);
    transform: skewX(45deg);
}
.navigationmenu   .innermenu .menuinside {
    display: block;
    min-height: 130px;
    opacity: 0;
    transform: translateY(-30px);
}
.navigationmenu .innermenu * {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -ms-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.navigationmenu .innermenu .menuinside .menuul {
    display: block;
    margin-bottom: 50px;
    padding: 0;
    position: relative;
}
.navigationmenu.active .innermenu .menuinside   {
    opacity: 1;
    transform: translateY(0);
}
.navigationmenu .innermenu .menuinside  {
    display: block;
    min-height: 130px;
    opacity: 0;
    transform: translateY(-30px);
}
.navigationmenu .innermenu .menuinside .menuul {

    min-height: 130px;
  
}

.menuul{
    transition-delay: 0s;
}
.navigationmenu .innermenu  .menuinside .menuul li {
    display: inline-block;
    margin: 0px 20px;
    padding: 0;
    list-style: none;
}
.navigationmenu .innermenu  .menuinside .menuul li a {
    display: inline-block;
    line-height: 1;
    font-size: 3vw;
    font-weight: 800;
    color: #fff;
    padding-bottom: 8px;
    border-bottom: 5px solid transparent;
    text-decoration: none;
     font-family: Extrabold; 
}
.navigationmenu.active .innermenu .menuinside{

        transition-delay: 0.45s;
    
}
.navigationmenu.innermenu .menuinside{

    transition-delay:0s;

}

.navigationmenu.active .innermenu .menuinside .menuul{

    transition-delay: 0.45s;

}
.navigationmenu .innermenu .menuinside .menuul{

transition-delay:0s;

}
.navigationmenu .innermenu .menuinside .menuul li a:hover {
    padding-bottom: 3px;
    border-bottom: 5px solid  #FD5B68;
    text-decoration: none;
}
.navigation-menu .bg-layers * {
     -webkit-transition: all 0.5s ease; 
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
     transition-duration: 700ms; 
    -webkit-transition-duration: 700ms;
     transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); 
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1100px !important;
    }
  }
  @media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1100px !important;
    }
  }

.trans{
    transition-delay: 0s;
}
.trans_reverse{
    transition-delay: 0.3s;
}
@media (max-width: 768px) {

    .hamburger-menu{
        margin: auto;
    }
    
.navigationmenu.active .bg-layers span:nth-child(1) {
    left: -50%;
    width: 50%;
}
.navigationmenu.active .bg-layers span:nth-child(2) {
    left: 0;
    width: 50%;
}

.navigationmenu.active .bg-layers span:nth-child(3) {
    left: 50%;
    width: 50%
}
.navigationmenu.active .bg-layers span:nth-child(4) {
    left: 100%;
    width: 50%;

}
.navigationmenu .innermenu  .menuinside .menuul li a {
    font-size: 9vw;
    padding-bottom: 2px
}
.navigationmenu .innermenu  .menuinside {
    overflow-y: scroll;
    height: calc(100vh - 96px);
    display: flex !important;
    justify-content: center;
    flex-direction: column;
}

.navigationmenu .innermenu  .menuinside .menuul li{
display: block;
margin-bottom: 20px;}
.navigationmenu .bg-layers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    /* background-color: #0e0e0e; */
    /* background-color: #0e0e0ef0; */
}

}
@media (max-width: 992px) {
    .navigationmenu .bg-layers {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        left: 0;
        top: 0;
        /* background-color: #0e0e0e; */
        background-color: #0e0e0e6e;
    }

  
}


    
    
  