body {
  font-family: fjalla;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow:inherit;
  margin: 0px !important;
  padding: 0px !important;

  

}
  

h6 ,h4{
  font-family: fjalla !important;
}
p,b,li,label{
  font-family:poppins, !important ;

}
h2,h1,h3{
  font-family:Extrabold !important;
  

}
a{
  cursor: pointer;
   
}





@font-face {
  font-family: fjalla;
  src: url(./Assets/fonts/FjallaOne-Regular.ttf);

}
@font-face {

  font-family: poppins;
  src: url(./Assets/fonts/Poppins-Regular.ttf);
}
@font-face {

  font-family: poppinsbold;
  src: url(./Assets/fonts/Poppins-Bold.ttf);
}
@font-face {

  font-family:Extrabold;
  src: url(./Assets/fonts/Poppins-ExtraBold.ttf);
}
@media (max-width: 768px){
  /* h2{
    font-family:poppinsbold !important;
    
  
   */
  


    
  } 