.footerbg{
    background-color: #0e0e0e;
    padding: 100px 0px 0px 0px !important;
    color: #fff;
    text-align: left; 
}
.footerimg{
text-align: left;
display: block;
    margin-bottom: 10px;
}
.footerrow1{
    margin-bottom: 80px; 
}
.anchorfooter{
    color: #fff;
    text-decoration: none;
}
.footerquoteimg{
    height: 60px;
    display: block;
    margin-bottom: 20px;
}



.footerbg p{
    font-size: 14px; 
    font-family: poppins;
}
.footerheading{
    font-size: 3vw;
    font-weight: 800;
    margin-bottom: 20px;
    color: #FD5B68;
    text-align: left;
    
}
.CONNECTUS{
    margin-bottom: 10px;
    font-family: fjalla ;
    text-transform: uppercase;
    color:#FD5B68; 
    font-weight: 400;
}
.socialink{
    color:#fff;
    text-decoration: none;

    
}
.socialinkul{
    list-style: none;
    display: block;
    padding: 0px;
    margin-bottom: 40px;

}
.socialinkul li{
    display: inline-block;
    margin-right: 20px;
}
.socialinkul li .socialink{
    position: relative;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 6px;
    transition: .25s ease-in-out;
    border-bottom: 2px solid transparent;
}
.copyright{
    opacity: 0.5;
    font-size: 13px;
    margin: 30px 0;
}
.footercontact{
   
    margin-bottom: 40px;
}
.footerow2{
    margin-bottom: 40px;
} 
.widget p a{ 
   font-family: poppins;
}







@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1100px!important;
    }}
    @media (max-width: 768px){
        .footerbg{
            
            padding: 80px 0px 0px 0px !important;
         
        }
        .footerheading{
            font-size: 8vw;
        }
        .footerquoteimg{
            margin-bottom: 40px;
        }
        .footerrow1 {
            margin-bottom: 40px;
        }
        .widget{
            margin-bottom: 40px;
        }
        .footerow2{
            margin-bottom: 40px;
        } 
        }
        