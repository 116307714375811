.knowmoresection{
    background-color: #01f7b6;
    padding: 150px 0px !important;
}
.knwmoresectitle{
    text-align: left;
    margin-bottom: 50px;

}
.knwmoresectitle h6 {
    width: 100%;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
    font-family: 'Fjalla One', sans-serif;
    opacity: 0.5;
    font-size: 14px;
}
.knwmoresectitle h2 {
    width: 100%;
    display: block;
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    position: relative;
    color: #0e0e0e;
   
}
.knwmoresectitle h2:before {
    content: attr(data-text);
    width: 100%;
    font-size: 20vw;
    opacity: 0.05;
    z-index: 0;
    position: absolute;
    left: 0;
    top: -20%;
}
.text-box{
    text-align: left;
}
    .text-box h4 {
        width: 100%;
        display: block;
        margin-top: 5px;
        font-family: 'Fjalla One', sans-serif;
        font-size: 25px;
        line-height: 40px;
        text-transform: uppercase;
        
    }
    
        .text-box .custom-link {
            margin-top: 30px;
        }
        @media (max-width: 768px) {
            .knowmoresection{
                padding: 80px 0px !important;
            }
            
.knwmoresectitle{
    margin-bottom: 30px;
}

.knwmoresectitle h2{
    font-size: 32px;
}
              }
           
          
