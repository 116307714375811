.ourinvestporttittal {
  margin-bottom: 50px;
  text-align: left;
}
.ourinvestporttittal h6 {
  line-height: 1;
  margin-bottom: 20px;

  opacity: 0.5;
  font-size: 14px;
}
.ourinvestporttittal h2 {
  margin-bottom: 0;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.2;
  position: relative;
  color: #0e0e0e;
}
.ALLROW .content-box2 .inner h3 span {
  display: block;
  font-size: 8vw;
  color: #fd5b68 !important;
}
.ourinvestporttittal h2:before {
  content: attr(data-text);
  width: 100%;
  font-size: 20vw;
  opacity: 0.05;
  z-index: 0;
  position: absolute;
  left: 0;
  top: -20%;
}
.ourinvestmentcon {
  padding: 150px 0px;
}
.ourinvesttittal {
  margin-bottom: 50px;
}
.ourinvesttittal h2 {
  margin-bottom: 0;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.2;
  position: relative;
  color: #0e0e0e;
  text-align: left;
}
.ourinvesttittal h2:before {
  content: attr(data-text);
  width: 100%;
  font-size: 20vw;
  opacity: 0.05;
  z-index: 0;
  position: absolute;
  left: 0;
  top: -20%;
}
.milvilaimg {
  width: 100%;
  height: auto;
}
.ALLROW {
  padding: 75px 0px;
}
.content-box2 .inner h3 span {
  font-size: 8vw;
  color: #fd5b68 !important;
  margin-right: -7vw;
}
.ALLROW:nth-child(2n + 2) .content-box2 h3 span {
  margin-left: -7vw;
}

.ALLROW:nth-child(2n + 1) .content-box2 h3 span {
  margin-right: -7vw;
}
.ALLROW .content-box2 .inner h3 span {
  font-size: 8vw;
  color: #fd5b68 !important;
  /* margin-left: -7vw;  */
  font-family: Extrabold;
  /* padding-right: 5%; */
}

.content-box2 .inner h3 {
  font-size: 5vw;
  font-weight: 800;
  margin-bottom: 10vh;
  line-height: 1;
}
z .ALLROW .content-box2 {
  width: 45%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.ALLROW:nth-child(2n + 2) .content-box2 {
  text-align: left;

  padding-right: 5%;
  padding-left: 5%;
}
/* .ALLROW:nth-child(2n+1) .content-box2 {
  
    text-align: right;
    
    padding-right: 5%;
    padding-left: 0%;
}  */

.milkvilabox {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.inner h3 {
  font-size: 8vw;
  color: #fd5b68 !important;

  font-weight: 800;
  line-height: 1.2;
}
.content-box2 {
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;

  z-index: 2;
  padding-left: 5%;
}
/* .ALLROW:nth-child(2n) .content-box2{
 
    text-align:left;
    padding-left: 5%;
    padding-right: 0%;
    

} */
.innerallcanfarm {
  text-align: left;
}
.content-box2 .inner h3 {
  font-size: 5vw;
  font-weight: 800;
  margin-bottom: 10vh;
  line-height: 1;
}
.custom-link2 .lines {
  width: 42px;
  height: 1px;
  float: left;
  overflow: hidden;
}
.custom-link2 b {
  font-weight: 600;
  display: inline-block;
  margin-left: 20px;
}

.lines span:nth-child(1) {
  width: 100%;
  height: 1px;
  float: right;
  background-color: #0e0e0e;
}
.lines span:nth-child(2) {
  width: 0;
  height: 1px;
  float: left;
  background-color: #0e0e0e;
}
.custom-link2:hover .lines {
  width: 62px;
  margin-left: 20px;
}
.custom-link2:hover .lines span:nth-child(1) {
  width: 0;
}

.custom-link2:hover .lines span:nth-child(2) {
  width: 100%;
  transition-delay: 0.2s;
}

.custom-link2 a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  color: #0e0e0e;
  text-decoration: none;
}
/* .ALLROW:nth-child(2n+1) .custom-link a{
    float: right;
   
   
   }   */
.ALLROW:nth-child(2n + 2) .custom-link2 a {
  float: left;
}
.ALLROW:nth-child(2n + 1) .custom-link2 a {
  float: right;
}
.ALLROW:hover .milkvilabox img {
  transform: scale(1.15);
}
.ALLROW:hover .milkvilabox {
  transform: scale(0.95);
}
.ALLROW .milkvilabox {
  margin: 0;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
}
.ALLROW:hover .imgbox {
  padding: 0px;
}
.ALLROW:nth-child(2n + 1) .content-box2 {
  text-align: right;
  padding-left: 0;
  padding-right: 5%;
}
.ALLROW * {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.custom-link2 * {
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -ms-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
@media (max-width: 768px) {
  .ourinvestmentcon {
    padding: 80px 0px;
  }

  .ourinvestporttittal {
    margin-bottom: 30px;
  }
  .ourinvestporttittal h2 {
    font-size: 32px;
  }

  .ALLROW:nth-child(2n + 2) .content-box2 {
    text-align: left;

    padding-right: 5%;
    padding-left: 5%;
  }
  .ALLROW {
    padding: 42px 0 !important;
  }
  .content-box2 {
    width: 100% !important;
  }
  .ALLROW:nth-child(2n+2) .custom-link2 a {
    float: left;
}
.content-box2 .inner h3 {
    font-size: 10vw;
    font-weight: 800;
    margin-bottom: 17px;
    line-height: 1;
    margin-top: 20px;
}
.ALLROW:nth-child(2n + 2) .content-box2 h3 span {
    margin-left: 0vw;
    margin-right: 0vw;

}
.ALLROW:nth-child(2n+1) .custom-link2 a {
    float: left;
}
.ALLROW:nth-child(2n + 1) .content-box2{
    text-align: left;
    padding-left: 0;
    padding-right: 0px;
}
.ALLROW:nth-child(2n + 1) .content-box2 h3 span {
    margin-right: 0px;
    margin-left: 0px;
}
.ALLROW:nth-child(2n + 2) .content-box2 {
    text-align: left;
    padding-right: 0px;
    padding-left: 0px;
}
}
