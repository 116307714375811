.ourinvestmentcon{
    padding: 150px 0px !important;
    
}
.ourinvesttittal{
    margin-bottom: 50px;
}
.ourinvesttittal h2{
    margin-bottom: 0;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    position: relative;
    color:#0e0e0e ;
    text-align: left;
}
.ourinvesttittal h2:before {
    content: attr(data-text);
    width: 100%;
    font-size: 20vw;
    opacity: 0.05;
    z-index: 0;
    position: absolute;
    left: 0;
    top: -20%;
}
.milvilaimg{
    width: 100%;
    height: auto;
}
.ALLROW{
    padding: 75px 0px;
}
.content-box .inner h3 span {
  
    font-size: 8vw;
    color: #FD5B68 !important;
    margin-left: -7vw;
    font-family: Extrabold !important;
}
.ALLROW:nth-child(2n+2) .content-box h3 span {
    margin-right: -7VW;

}

.content-box .inner h3 {
    font-size: 5vw;
    font-weight: 800;
    margin-bottom: 10vh;
    line-height: 1;
}
 .ALLROW:nth-child(2n+2) .content-box {
  
    text-align: right;
    padding-left: 0;
    padding-right: 5%;
} 
 
.ALLROW .content-box {
    width: 45%;
    padding-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
}

.milkvilabox{
 position: relative;
 overflow: hidden;
 margin-bottom: 0;
}
.inner h3 {
    font-size: 8vw;
    color: #FD5B68 !important;
 
    font-weight: 800;
    line-height: 1.2;
}


/* 
.ALLROW:nth-child(2n+1) .content-box{
 
    text-align: left;
    padding-left: 5%;
    padding-right:0%;
    

} */

.content-box .inner h3 {
    font-size: 5vw;
    font-weight: 800;
    margin-bottom: 10vh;
    line-height: 1;
}




.lines {
    width: 42px;
    height: 1px;
    float: left;
    overflow: hidden;
}
.lines span:nth-child(1) {
    width: 100%;
    height: 1px;
    float: right;
    background-color:#0e0e0e;
}
.lines span:nth-child(2) {
    width: 0;
    height: 1px;
    float: left;
    background-color:#0e0e0e;
}
.custom-link:hover .lines {
    width: 62px;
    margin-left: 20px;
}
.custom-link:hover .lines span:nth-child(1) {
    width: 0;
}

.custom-link:hover .lines span:nth-child(2) {
    width: 100%;
    transition-delay: 0.20s;
}


.custom-link a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: #0e0e0e; 
    text-decoration: none;
}
.ALLROW:nth-child(2n+2) .custom-link a{
 float: right;


}


.custom-link b {
    font-weight: 600;
    display: inline-block;
    margin-left: 20px;
}
.ALLROW:hover .milkvilabox img {
    transform: scale(1.15);
}
.ALLROW:hover .milkvilabox  {
    transform: scale(0.95);
   
}
.ALLROW  .milkvilabox  {

    margin: 0;
    position: relative;
    overflow: hidden;
    border: 1px solid transparent;
}
.ALLROW:hover .imgbox  {
  padding: 0px;
   
}
.ALLROW *{
    
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -ms-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    
} 
.custom-link * {
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -ms-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
@media (max-width: 768px) {
    .ourinvesttittal{
    margin-bottom: 30px;}
    .ourinvesttittal h2 {
        font-size: 32px !important;
        
       
      }
      .ourinvestmentcon{
        padding: 80px 0px !important;
      }
      .ALLROW{
        padding: 35px 0 !important;
      }
    
     .custom-link b {
    font-family: poppins;
    } 
    .ALLROW{
        flex-direction: column-reverse;

    }
    .milkvilabox{
        width: 100% !important;
    }
     .content-box{
        width: 100% !important;
    } 
    .ALLROW:nth-child(2n+2) .content-box {
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
    }
    .ALLROW:nth-child(2n+2) .content-box h3 span {
        margin-left: 0px;
        margin-top: 20px;
    }
    .imgbox{
        width: 100%!important;
    }
    .Allrow2{
        flex-direction: column;
    }
    .ALLROW:nth-child(2n+2) .custom-link a {
        float: left;
    }
    .content-box .inner h3 {
        font-size: 10vw;
        font-weight: 800;
        margin-bottom: 17px;
        line-height: 1;
        margin-top: 20px;
    }
    .ALLROW .content-box {
   
         padding-left: 0px; 
       
    }
    .content-box .inner h3 span {
     
         margin-left: 0px; 
       ;
     
    }
  }
  